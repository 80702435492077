import { environment } from '../../environments/environment';
export const adminMenuItems = [
    {
        label: $localize `Organizer`,
        items: [
            {
                label: $localize `Naročila`,
                icon: 'fa-solid fa-cart-shopping',
                routerLink: '/orders',
                items: [
                    {
                        label: $localize `Novo`,
                        icon: 'fa-solid fa-plus',
                        routerLink: '/orders/new',
                    },
                    {
                        label: $localize `Pregled`,
                        icon: 'fa-solid fa-list-ul',
                        routerLink: '/orders',
                    },
                    {
                        label: $localize `Statistika`,
                        icon: 'fa-solid fa-pie-chart',
                        routerLink: '/dashboard',
                    },
                ]
            },
            {
                label: $localize `Nastavitve`,
                icon: 'fa-solid fa-sliders',
                items: [
                    {
                        label: $localize `Statusi`,
                        icon: 'fa-solid fa-eye',
                        routerLink: '/settings/status',
                    },
                    {
                        label: $localize `Prioritete`,
                        icon: 'fa-solid fa-bell',
                        routerLink: '/settings/priorities',
                    },
                    {
                        label: $localize `Materiali`,
                        icon: 'fa-solid fa-toilet-paper',
                        routerLink: '/settings/materials',
                    },
                    {
                        label: $localize `Dimenzije`,
                        icon: 'fa-solid fa-ruler-horizontal',
                        routerLink: '/settings/dimensions',
                    },
                    {
                        label: $localize `Formati`,
                        icon: 'fa-solid fa-map',
                        routerLink: '/formats',
                    },
                    {
                        label: $localize `Polja po meri`,
                        icon: 'fa-solid fa-pen-to-square',
                        routerLink: '/settings/custom-fields',
                    },
                    {
                        label: $localize `Organizator`,
                        icon: 'fa-solid fa-table',
                        routerLink: '/settings/organizer',
                    },
                    {
                        label: $localize `Predloge`,
                        icon: 'fa-solid fa-envelope-open-text',
                        routerLink: '/settings/templates'
                    },
                    {
                        label: $localize `Tagi`,
                        icon: 'fa-solid fa-tags',
                        routerLink: '/settings/tags'
                    },
                    {
                        label: $localize `Sistem`,
                        icon: 'fa-solid fa-gear',
                        routerLink: '/settings/system',
                    }
                ],
            },
            {
                label: $localize `Produkti`,
                icon: 'fa-solid fa-briefcase',
                routerLink: '/services',
            },
            {
                label: $localize `Uporabniki`,
                icon: 'fa-solid fa-user',
                routerLink: '/users',
            },
            {
                label: 'Partnerji',
                icon: 'fa-solid fa-user-tie',
                routerLink: '/partners'
            },
            {
                label: $localize `Planiranje`,
                routerLink: '/planning',
                icon: 'fa-solid fa-check-square'
            },
            {
                label: $localize `Projekti`,
                routerLink: '/projects',
                icon: 'fa-solid fa-briefcase',
            },
            {
                label: $localize `Podpisovanje`,
                routerLink: '/signature',
                icon: 'fa-solid fa-signature',
            },
            {
                label: $localize `Baza znanja`,
                routerLink: '/knowledge',
                icon: 'fa-solid fa-book',
            },
            {
                label: $localize `Info`,
                routerLink: '/info',
                icon: 'fa-solid fa-circle-info',
            },
            {
                label: $localize `Prisotnost`,
                routerLink: '/hours',
                icon: 'fa-solid fa-clock'
            }
        ]
    },
    {
        label: $localize `Organizator`,
        routerLink: '/organizer'
    },
    {
        label: $localize `Naročila`,
        routerLink: '/orders'
    },
    {
        label: undefined,
        icon: 'fa-solid fa-calendar',
        routerLink: '/organizer/cal',
        tooltip: $localize `Koledarski pogled`
    },
    {
        label: undefined,
        icon: 'fa-solid fa-plus',
        routerLink: '/orders/new',
        tooltip: $localize `Novo naročilo`
    },
    {
        label: undefined,
        icon: 'fa-solid fa-paste',
        routerLink: '/clip',
        tooltip: $localize `Odložišče`,
        tooltipPosition: 'bottom',
    },
];
export const editorMenuItems = [
    {
        label: 'Organizer',
        items: [
            {
                label: $localize `Naročila`,
                icon: 'fa-solid fa-cart-shopping',
                routerLink: '/orders',
                items: [
                    {
                        label: $localize `Novo`,
                        icon: 'fa-solid fa-plus',
                        routerLink: '/orders/new',
                    },
                    {
                        label: $localize `Pregled`,
                        icon: 'fa-solid fa-list-ul',
                        routerLink: '/orders',
                    },
                ]
            }
        ]
    },
    {
        label: $localize `Organizator`,
        routerLink: '/organizer'
    },
    {
        label: $localize `Naročila`,
        routerLink: '/orders'
    },
    {
        label: undefined,
        icon: 'fa-solid fa-calendar',
        routerLink: '/organizer/cal',
        tooltip: $localize `Koledarski pogled`,
    },
    {
        label: undefined,
        icon: 'fa-solid fa-plus',
        routerLink: '/orders/new',
        tooltip: $localize `Novo naročilo`,
        tooltipPosition: 'bottom',
    },
    /* {
      label: undefined,
      icon: 'fa-solid fa-paste',
      routerLink: '/clip',
      tooltip:  $localize`Odložišče`,
      tooltipPosition: 'bottom',
    }, */
];
if (environment.calendar) {
    const calendarMenuItem = {
        label: $localize `Koledar`,
        routerLink: '/calendar'
    };
    editorMenuItems.push(calendarMenuItem);
    adminMenuItems.push(calendarMenuItem);
}
export const externalMenuItems = [
    {
        label: $localize `Naročila`,
        routerLink: '/orders'
    }
];
export const customerMenuItems = [
    {
        label: $localize `Naročila`,
        routerLink: '/orders'
    }
];
