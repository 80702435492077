import { HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { throwError } from 'rxjs';
import { AuthService } from './auth.service';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CLIENT_ID } from '../../const';
import * as i0 from "@angular/core";
import * as i1 from "./auth.service";
import * as i2 from "@angular/router";
export class AuthInterceptorService {
    authService;
    router;
    static ERROR_STATUS_REDIRECT_CODES = [401, 403, 413];
    constructor(authService, router) {
        this.authService = authService;
        this.router = router;
    }
    intercept(req, next) {
        let clonedRequest;
        if (this.authService.apiKey()) {
            clonedRequest = req.clone({
                headers: req.headers
                    .set('Authorization', this.authService.apiKey())
                    .set('X-Client-Id', CLIENT_ID),
            });
        }
        else {
            clonedRequest = req.clone({
                headers: req.headers.set('X-Client-Id', CLIENT_ID),
            });
        }
        return next.handle(clonedRequest)
            .pipe(catchError((err) => {
            if (AuthInterceptorService.ERROR_STATUS_REDIRECT_CODES.includes(err.status)) {
                this.authService.logout(this.router.routerState.snapshot.url);
            }
            return throwError(() => err);
        }));
    }
    static ɵfac = function AuthInterceptorService_Factory(t) { return new (t || AuthInterceptorService)(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.Router)); };
    static ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: AuthInterceptorService, factory: AuthInterceptorService.ɵfac, providedIn: 'root' });
}
