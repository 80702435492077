/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class FinanceService {
    http;
    constructor(http) {
        this.http = http;
    }
    createInvoice(data) {
        return this.http.post(`${environment.base}/finance/issuedInvoices/create-invoice/${data.orderId}`, data);
    }
    createProformaInvoice(orderId, items) {
        return this.http.post(`${environment.base}/finance/issuedInvoices/create-proforma-invoice/${orderId}`, items);
    }
    createDeliveryNote(orderId, items) {
        return this.http.post(`${environment.base}/document/create-delivery-note/${orderId}`, items);
    }
    syncPartners() {
        return this.http.put(`${environment.base}/finance/sync/partners`, undefined);
    }
    getPaymentMethods() {
        return this.http.get(`${environment.base}/finance/paymentmethods`)
            .pipe(map(resp => resp.data));
    }
    getVatRates() {
        return this.http.get(`${environment.base}/finance/vatrates`)
            .pipe(map(resp => resp.data));
    }
    getCountries() {
        return this.http.get(`${environment.base}/finance/countries`)
            .pipe(map(resp => resp.data));
    }
    getConfig() {
        return this.http.get(`${environment.base}/finance/config`)
            .pipe(map(resp => resp.data));
    }
    syncPartner(id) {
        return this.http.put(`${environment.base}/finance/sync/partners/${id}`, undefined);
    }
    static ɵfac = function FinanceService_Factory(t) { return new (t || FinanceService)(i0.ɵɵinject(i1.HttpClient)); };
    static ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: FinanceService, factory: FinanceService.ɵfac });
}
