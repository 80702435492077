export class SearchAction {
    ctx;
    static type = '[search] search action';
    constructor(ctx) {
        this.ctx = ctx;
    }
    static dispatch(ctx) {
        return new SearchAction(ctx);
    }
}
