import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import * as i0 from "@angular/core";
import * as i1 from "./auth.service";
import * as i2 from "@angular/router";
export class WriteGuardService {
    authService;
    router;
    constructor(authService, router) {
        this.authService = authService;
        this.router = router;
    }
    canActivate() {
        return this.writeGuard();
    }
    canLoad() {
        return this.writeGuard();
    }
    async writeGuard() {
        const auth = this.authService.canWrite;
        if (!auth) {
            await this.router.navigate(['/orders']);
            return false;
        }
        return true;
    }
    static ɵfac = function WriteGuardService_Factory(t) { return new (t || WriteGuardService)(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.Router)); };
    static ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: WriteGuardService, factory: WriteGuardService.ɵfac, providedIn: 'root' });
}
