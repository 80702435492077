import { ElementRef, inject } from '@angular/core';
import { ChatState } from '../state';
import { tap } from 'rxjs';
import { Store } from '@ngxs/store';
import { GlobalState } from '../../state';
import { SetChatVisibilityAction } from '../state/actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngxs/store";
import * as i2 from "@angular/common";
import * as i3 from "@angular/router";
import * as i4 from "primeng/tooltip";
import * as i5 from "../chat-input/chat-input.component";
const _c0 = ["chatGrid"];
const _c1 = a0 => ["/partners", a0];
const _c2 = a0 => ["/orders/info", a0];
function ChatWindowComponent_ng_container_0_div_6_div_11_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 16)(1, "a", 17);
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd()();
} if (rf & 2) {
    const partner_r3 = ctx.ngIf;
    i0.ɵɵadvance();
    i0.ɵɵproperty("routerLink", i0.ɵɵpureFunction1(2, _c1, partner_r3.id));
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1("Partner: ", partner_r3.title, "");
} }
function ChatWindowComponent_ng_container_0_div_6_div_12_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 16)(1, "a", 17);
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "span", 13);
    i0.ɵɵtext(4);
    i0.ɵɵelementEnd()();
} if (rf & 2) {
    const order_r4 = ctx.ngIf;
    i0.ɵɵadvance();
    i0.ɵɵproperty("routerLink", i0.ɵɵpureFunction1(4, _c2, order_r4.id));
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate2("Naro\u010Dilo: ", order_r4.enumeration, " /", order_r4.year, "");
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(order_r4.title);
} }
function ChatWindowComponent_ng_container_0_div_6_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 8)(1, "div", 9);
    i0.ɵɵelement(2, "img", 10);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "div", 11)(4, "div", 12);
    i0.ɵɵtext(5);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(6, "div", 13);
    i0.ɵɵtext(7);
    i0.ɵɵpipe(8, "date");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(9, "div", 14);
    i0.ɵɵtext(10);
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(11, ChatWindowComponent_ng_container_0_div_6_div_11_Template, 3, 4, "div", 15)(12, ChatWindowComponent_ng_container_0_div_6_div_12_Template, 5, 6, "div", 15);
    i0.ɵɵelementEnd()();
} if (rf & 2) {
    const m_r5 = ctx.$implicit;
    const me_r6 = i0.ɵɵnextContext().ngIf;
    i0.ɵɵclassProp("flex-row-reverse", me_r6.id === m_r5.user.id)("flex-row", me_r6.id !== m_r5.user.id);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("src", m_r5.user.image, i0.ɵɵsanitizeUrl)("alt", m_r5.user.title);
    i0.ɵɵadvance();
    i0.ɵɵclassProp("align-items-end", me_r6.id === m_r5.user.id);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate1(" ", m_r5.user.title, " ");
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind2(8, 15, m_r5.createdAt, "dd.MM.yyyy HH:mm:ss"), " ");
    i0.ɵɵadvance(2);
    i0.ɵɵclassProp("text-right", me_r6.id === m_r5.user.id);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", m_r5.text, " ");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", m_r5.partner);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", m_r5.order);
} }
function ChatWindowComponent_ng_container_0_Template(rf, ctx) { if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "div", 2)(2, "div", 3)(3, "i", 4);
    i0.ɵɵlistener("click", function ChatWindowComponent_ng_container_0_Template_i_click_3_listener() { i0.ɵɵrestoreView(_r1); const ctx_r1 = i0.ɵɵnextContext(); return i0.ɵɵresetView(ctx_r1.setChatVisibility(false)); });
    i0.ɵɵelementEnd()()();
    i0.ɵɵelementStart(4, "div", 5, 0);
    i0.ɵɵtemplate(6, ChatWindowComponent_ng_container_0_div_6_Template, 13, 18, "div", 6);
    i0.ɵɵpipe(7, "async");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(8, "div", 7)(9, "div", 3);
    i0.ɵɵelement(10, "mvm-chat-input");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementContainerEnd();
} if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance(6);
    i0.ɵɵproperty("ngForOf", i0.ɵɵpipeBind1(7, 1, ctx_r1.messages$));
} }
export class ChatWindowComponent {
    store;
    vc;
    me$ = inject(Store).select(GlobalState.me);
    messages$;
    constructor(store) {
        this.store = store;
        this.messages$ = this.store.select(ChatState.messages)
            .pipe(tap(() => setTimeout(() => this.vc?.nativeElement.scrollTo({
            behavior: 'smooth',
            top: this.vc?.nativeElement.scrollHeight + this.vc?.nativeElement.clientHeight,
        }), 500)));
    }
    setChatVisibility(b) {
        this.store.dispatch(SetChatVisibilityAction.dispatch(false));
    }
    static ɵfac = function ChatWindowComponent_Factory(t) { return new (t || ChatWindowComponent)(i0.ɵɵdirectiveInject(i1.Store)); };
    static ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: ChatWindowComponent, selectors: [["mvm-chat-window"]], viewQuery: function ChatWindowComponent_Query(rf, ctx) { if (rf & 1) {
            i0.ɵɵviewQuery(_c0, 5);
        } if (rf & 2) {
            let _t;
            i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.vc = _t.first);
        } }, decls: 2, vars: 3, consts: () => { let i18n_0; if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
            /**
             * @suppress {msgDescriptions}
             */
            const MSG_EXTERNAL_8765994967232179845$$APPS_MVM_SRC_APP_CHAT_CHAT_WINDOW_CHAT_WINDOW_COMPONENT_TS_0 = goog.getMsg("Skrij");
            i18n_0 = MSG_EXTERNAL_8765994967232179845$$APPS_MVM_SRC_APP_CHAT_CHAT_WINDOW_CHAT_WINDOW_COMPONENT_TS_0;
        }
        else {
            i18n_0 = $localize `Skrij`;
        } return [["chatGrid", ""], [4, "ngIf"], [1, "p-grid", "sysbar"], [1, "col-12"], ["pTooltip", i18n_0, 1, "fa", "fa-window-minimize", "cursor-pointer", 3, "click"], [1, "p-grid", 2, "max-height", "60vh", "overflow-y", "scroll"], ["class", "col-12 d-flex flex", 3, "flex-row-reverse", "flex-row", 4, "ngFor", "ngForOf"], [1, "p-grid"], [1, "col-12", "d-flex", "flex"], [1, "px-2"], [1, "user-avatar", 3, "src", "alt"], [1, "d-flex", "flex", "flex-1", "flex-column"], [1, "text-med", "font-bold"], [1, "text-mini"], [1, "text-msg"], ["class", "chat-ref flex flex-column", 4, "ngIf"], [1, "chat-ref", "flex", "flex-column"], [1, "text-mini", "font-bold", 3, "routerLink"]]; }, template: function ChatWindowComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵtemplate(0, ChatWindowComponent_ng_container_0_Template, 11, 3, "ng-container", 1);
            i0.ɵɵpipe(1, "async");
        } if (rf & 2) {
            i0.ɵɵproperty("ngIf", i0.ɵɵpipeBind1(1, 1, ctx.me$));
        } }, dependencies: [i2.NgForOf, i2.NgIf, i3.RouterLink, i4.Tooltip, i5.ChatInputComponent, i2.AsyncPipe, i2.DatePipe], styles: ["[_nghost-%COMP%] {\n  background: #fff;\n  border: 1px solid gray;\n  position: fixed;\n  bottom: 0;\n  right: 2rem;\n  max-width: 30vw;\n  z-index: 1666;\n}\n@media (max-width: 992px) {\n  [_nghost-%COMP%] {\n    max-width: 50vw;\n  }\n}\n@media (max-width: 768px) {\n  [_nghost-%COMP%] {\n    max-width: 90vw;\n  }\n}\n\n.user-avatar[_ngcontent-%COMP%] {\n  display: block;\n  height: 30px;\n  width: 30px;\n  border-radius: 50%;\n}\n\n.sysbar[_ngcontent-%COMP%] {\n  background: black;\n  color: white;\n}\n\n.chat-ref[_ngcontent-%COMP%] {\n  padding: 0.2rem;\n  margin: 0.2rem;\n  border: 0 1px #2B2B30;\n  background: #F7F9FA;\n}"] });
}
