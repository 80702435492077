/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient, HttpParams } from '@angular/common/http';
import { EItemType, IApiResponse, ICreateAndSignDeliveryNoteDto, IDocument, IFTPListing, IOrder, ISearchCtx } from '@mvm/api-interfaces';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { API } from './apiutil';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class DocumentService {
    http;
    constructor(http) {
        this.http = http;
    }
    download(id) {
        return this.http.get(`${environment.base}/document/${id}`, {
            responseType: 'arraybuffer',
            observe: 'response'
        })
            .pipe(map(resp => {
            return new Blob([resp.body], { type: resp.headers.get('content-type') });
        }));
    }
    uploadFile(id, type, formData) {
        return this.http.post(`${environment.base}/document/${id}/${type}`, formData, { observe: 'events', reportProgress: true });
    }
    uploadInternal(key, formData) {
        return this.http.post(`${environment.base}/document/internal/upload/${key}`, formData, { observe: 'body', reportProgress: false });
    }
    search(ctx) {
        return this.http.get(`${environment.base}/document`, { params: API.makeParams(ctx) });
    }
    listFTP(order, base) {
        return this.http.get(`${environment.base}/document/ftp/${order.id}`, {
            params: new HttpParams().append('base', base)
        });
    }
    listFTPFiles(order, base) {
        return this.http.get(`${environment.base}/document/ftp/${order.id}/files`, {
            params: new HttpParams().append('base', base)
        });
    }
    downloadFtp(id, file) {
        return this.http.get(`${environment.base}/document/ftp/file/${id}`, {
            responseType: 'arraybuffer',
            observe: 'response',
            params: new HttpParams().append('file', file)
        })
            .pipe(map((response) => new Blob([response.body], { type: response.headers.get('content-type') })));
    }
    createFtpDir(order, dirPath) {
        return this.http.post(`${environment.base}/document/ftp/create-dir/${order.id}`, { dirPath });
    }
    createBaseFtp(order) {
        return this.http.put(`${environment.base}/document/ftp/create/${order.id}`, undefined);
    }
    convert(docId) {
        return this.http.put(`${environment.base}/document/convert/${docId}`, undefined);
    }
    createAndSignDeliveryNote(orderId, payload) {
        return this.http.post(`${environment.base}/document/create-and-sign-delivery-note/${orderId}`, payload);
    }
    uploadClipFile(id, formData) {
        console.log(id, "clipid");
        return this.http.post(`${environment.base}/document/upload/clip/${id}`, formData, { observe: 'events', reportProgress: true });
    }
    static ɵfac = function DocumentService_Factory(t) { return new (t || DocumentService)(i0.ɵɵinject(i1.HttpClient)); };
    static ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: DocumentService, factory: DocumentService.ɵfac });
}
