export class PostMessageAction {
    message;
    static type = '[chat] post message';
    constructor(message) {
        this.message = message;
    }
    static dispatch(message) {
        return new PostMessageAction(message);
    }
}
export class AppendMessageAction {
    message;
    static type = '[chat] append message';
    constructor(message) {
        this.message = message;
    }
    static dispatch(message) {
        return new AppendMessageAction(message);
    }
}
export class SetChatRefAction {
    ref;
    static type = '[chat] set chat ref';
    constructor(ref) {
        this.ref = ref;
    }
    static dispatch(ref) {
        return new SetChatRefAction(ref);
    }
}
export class SearchMessagesAction {
    ctx;
    static type = '[chat] search messages';
    constructor(ctx) {
        this.ctx = ctx;
    }
    static dispatch(ctx) {
        return new SearchMessagesAction(ctx);
    }
}
export class SetAuthStateAction {
    hasAuth;
    static type = '[chat] set auth message';
    constructor(hasAuth = false) {
        this.hasAuth = hasAuth;
    }
    static dispatch(hasAuth = false) {
        return new SetAuthStateAction(hasAuth);
    }
}
export class SetChatVisibilityAction {
    visible;
    static type = '[chat] set visibility';
    constructor(visible = false) {
        this.visible = visible;
    }
    static dispatch(visible = false) {
        return new SetChatVisibilityAction(visible);
    }
}
