import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { API } from './apiutil';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class ServicesService {
    http;
    constructor(http) {
        this.http = http;
    }
    getAllMain() {
        return this.http.get(`${environment.base}/services`);
    }
    searchMainServices(ctx) {
        return this.http.get(`${environment.base}/services/search`, { params: API.makeParams(ctx) });
    }
    getServiceCategoriesFor(id) {
        return this.http.get(`${environment.base}/services/${id}`);
    }
    removePrice(priceId) {
        return this.http.delete(`${environment.base}/services/price/${priceId}`);
    }
    removeCategoryLink(id) {
        return this.http.delete(`${environment.base}/services/category-link/${id}`);
    }
    removeServiceCategory(serviceId, serviceCategoryId) {
        return this.http.delete(`${environment.base}/services/service-category/${serviceId}/${serviceCategoryId}`);
    }
    readProduct(id) {
        return this.http.get(`${environment.base}/services/product/${id}`);
    }
    createProduct(product) {
        return this.http.post(`${environment.base}/services/product`, product);
    }
    updateProduct(product) {
        return this.http.patch(`${environment.base}/services/product`, product);
    }
    removeProduct(id) {
        return this.http.delete(`${environment.base}/services/product/${id}`);
    }
    download(ids) {
        return this.http.put(`${environment.base}/services/export/xlsx`, { ids }, {
            responseType: 'arraybuffer',
            observe: 'response'
        })
            .pipe(map(resp => new Blob([resp.body], { type: resp.headers.get('content-type') })));
    }
    importXLSX(formData) {
        return this.http.post(`${environment.base}/services/import/xlsx`, formData, {
            observe: 'events',
            reportProgress: true,
        });
    }
    static ɵfac = function ServicesService_Factory(t) { return new (t || ServicesService)(i0.ɵɵinject(i1.HttpClient)); };
    static ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: ServicesService, factory: ServicesService.ɵfac });
}
