import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class NotificationService {
    http;
    constructor(http) {
        this.http = http;
    }
    sendSms(data) {
        return this.http.post(`${environment.base}/notification/sms/send`, data)
            .pipe(map(resp => resp.data));
    }
    sendEmail(data) {
        return this.http.post(`${environment.base}/notification/email/send`, data)
            .pipe(map(resp => resp.data));
    }
    static ɵfac = function NotificationService_Factory(t) { return new (t || NotificationService)(i0.ɵɵinject(i1.HttpClient)); };
    static ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: NotificationService, factory: NotificationService.ɵfac });
}
