import { __decorate, __metadata } from "tslib";
import { Router } from '@angular/router';
import { HotkeysService } from '@ngneat/hotkeys';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as i0 from "@angular/core";
import * as i1 from "@ngneat/hotkeys";
import * as i2 from "@angular/router";
let AppComponent = class AppComponent {
    hotkeys;
    router;
    constructor(hotkeys, router) {
        this.hotkeys = hotkeys;
        this.router = router;
    }
    ngOnInit() {
        this.hotkeys.addShortcut({ keys: 'shift.n' })
            .pipe(untilDestroyed(this))
            .subscribe(() => {
            this.router.navigateByUrl('orders/new');
        });
    }
    static ɵfac = function AppComponent_Factory(t) { return new (t || AppComponent)(i0.ɵɵdirectiveInject(i1.HotkeysService), i0.ɵɵdirectiveInject(i2.Router)); };
    static ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: AppComponent, selectors: [["mvm-root"]], decls: 1, vars: 0, template: function AppComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵelement(0, "router-outlet");
        } }, dependencies: [i2.RouterOutlet], encapsulation: 2 });
};
AppComponent = __decorate([
    UntilDestroy(),
    __metadata("design:paramtypes", [HotkeysService,
        Router])
], AppComponent);
export { AppComponent };
