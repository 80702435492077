import { CommonModule } from '@angular/common';
import { PagesModule } from '../pages/pages.module';
import { NgxsModule } from '@ngxs/store';
import { GlobalState } from '../state';
import { ChatState } from './state';
import { AuthModule } from '../auth/auth.module';
import { ChatService } from './chat.service';
import * as i0 from "@angular/core";
import * as i1 from "@ngxs/store";
export class ChatModule {
    static ɵfac = function ChatModule_Factory(t) { return new (t || ChatModule)(); };
    static ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: ChatModule });
    static ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ providers: [ChatService], imports: [CommonModule,
            PagesModule,
            NgxsModule.forFeature([GlobalState, ChatState]),
            AuthModule] });
}
