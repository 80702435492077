import { ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSlimScrollModule } from 'ngx-slimscroll';
import { UploaderModule } from './uploader/uploader.module';
import { PaginatorModule } from 'primeng/paginator';
import { HotkeysModule } from '@ngneat/hotkeys';
import { ContextMenuModule } from 'primeng/contextmenu';
import { NgxsModule } from '@ngxs/store';
import { GlobalState } from '../state';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import * as i0 from "@angular/core";
import * as i1 from "@ngxs/store";
export class SharedModule {
    static forRoot() {
        return {
            ngModule: SharedModule,
            providers: [],
        };
    }
    static ɵfac = function SharedModule_Factory(t) { return new (t || SharedModule)(); };
    static ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: SharedModule });
    static ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ imports: [CalendarModule,
            CommonModule,
            FormsModule,
            ReactiveFormsModule,
            HttpClientModule,
            NgSlimScrollModule,
            UploaderModule,
            PaginatorModule,
            HotkeysModule,
            ContextMenuModule,
            NgxsModule.forFeature([GlobalState]),
            AutoCompleteModule, CommonModule,
            FormsModule,
            ReactiveFormsModule,
            HttpClientModule,
            NgSlimScrollModule,
            PaginatorModule,
            UploaderModule,
            HotkeysModule,
            ContextMenuModule,
            CalendarModule] });
}
