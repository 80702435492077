import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { BlockUIModule } from 'primeng/blockui';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { DragDropModule } from 'primeng/dragdrop';
import { DropdownModule } from 'primeng/dropdown';
import { EditorModule } from 'primeng/editor';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MenubarModule } from 'primeng/menubar';
import { MessageModule } from 'primeng/message';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { MultiSelectModule } from 'primeng/multiselect';
import { ToastModule } from 'primeng/toast';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TimelineModule } from 'primeng/timeline';
import { CardModule } from 'primeng/card';
import { SharedModule } from '../shared/shared.module';
import * as i0 from "@angular/core";
export class PagesModule {
    static ɵfac = function PagesModule_Factory(t) { return new (t || PagesModule)(); };
    static ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: PagesModule });
    static ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ imports: [ButtonModule,
            SharedModule,
            MenubarModule,
            InputTextModule,
            InputTextareaModule,
            FormsModule,
            ReactiveFormsModule,
            TableModule,
            MessageModule,
            DragDropModule,
            CommonModule,
            DialogModule,
            CheckboxModule,
            AutoCompleteModule,
            DropdownModule,
            CalendarModule,
            EditorModule,
            TooltipModule,
            MultiSelectModule,
            BlockUIModule,
            RadioButtonModule,
            SharedModule,
            SharedModule, ButtonModule,
            SharedModule,
            MenubarModule,
            InputTextModule,
            FormsModule,
            ReactiveFormsModule,
            TableModule,
            MessageModule,
            DragDropModule,
            DialogModule,
            InputTextareaModule,
            CheckboxModule,
            AutoCompleteModule,
            DropdownModule,
            CalendarModule,
            EditorModule,
            TooltipModule,
            MultiSelectModule,
            BlockUIModule,
            ToastModule,
            RadioButtonModule,
            TimelineModule,
            CardModule] });
}
