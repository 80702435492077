import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthInterceptorService } from './auth-interceptor.service';
import { AuthService } from './auth.service';
import { WriteGuardService } from './write-guard.service';
import { UserGuardService } from './user-guard.service';
import * as i0 from "@angular/core";
export class AuthModule {
    static ɵfac = function AuthModule_Factory(t) { return new (t || AuthModule)(); };
    static ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: AuthModule });
    static ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ providers: [
            AuthService,
            AuthInterceptorService,
            {
                provide: HTTP_INTERCEPTORS,
                multi: true,
                useClass: AuthInterceptorService,
            },
            WriteGuardService,
            UserGuardService
        ], imports: [HttpClientModule] });
}
