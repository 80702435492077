var GlobalState_1;
import { __decorate, __metadata } from "tslib";
import { Action, createSelector, Selector, State } from '@ngxs/store';
import { CreateColumnAction, CreateMaterialAction, CreatePriorityAction, CreateStatusAction, CreateTagAction, CreateTemplateAction, DeleteMaterialAction, DeletePriorityAction, DeleteStatusAction, DeleteTagAction, DeleteTemplateAction, ErrorAction, LoadConfigurationAction, LoadFinanceConfigSpecsAction, LoadSettingsConfigSpecsAction, PatchTemplateAction, SaveConfigurationAction, SaveCustomFieldAction, SearchCustomFieldsAction, SearchDimensionCategoriesAction, SearchMaterialsAction, SearchPrioritiesAction, SearchStatusesAction, SearchTagsAction, SearchTemplatesAction, SetUserAction, SuccessAction, SyncAllPartnersAction, SyncPartnerAction } from './actions';
import { Observable } from 'rxjs';
import { SettingsService } from '../api/settings.service';
import { tap } from 'rxjs/operators';
import { FinanceService } from '../api/finance.service';
import { MessageService } from 'primeng/api';
import * as i0 from "@angular/core";
import * as i1 from "../api/settings.service";
import * as i2 from "primeng/api";
import * as i3 from "../api/finance.service";
let GlobalState = class GlobalState {
    static { GlobalState_1 = this; }
    settingsService;
    messageService;
    financeService;
    static customFields(state) {
        return state.customFields;
    }
    static dimensionCategories(state) {
        return state.dimensionCategories;
    }
    static financeConfigSpec(state) {
        return state.financeConfigSpec || [];
    }
    static config(state) {
        return state.config || [];
    }
    static configVar(name) {
        return createSelector([GlobalState_1], (state) => state.config.find(c => c.name === name)?.value);
    }
    static settingsConfigSpec(state) {
        return state.settingsConfigSpec || [];
    }
    static loadingConfig(state) {
        return state.loadingConfig;
    }
    static configSpecs(state) {
        return [
            ...GlobalState_1.settingsConfigSpec(state),
            ...GlobalState_1.financeConfigSpec(state),
        ];
    }
    static tags(state) {
        return state.tags;
    }
    static loadingTags(state) {
        return state.loadingTags;
    }
    static me(state) {
        return state.me || null;
    }
    static loadingStatuses(state) {
        return state.loadingStatuses;
    }
    static statuses(state) {
        return state.statuses;
    }
    static materials(state) {
        return state.materials;
    }
    static statusDeleted(state) {
        return state.statusDeleted;
    }
    static materialDeleted(state) {
        return state.materialDeleted;
    }
    static priorities(state) {
        return state.priorities;
    }
    static loadingPriorities(state) {
        return state.loadingPriorities;
    }
    static loadingMaterials(state) {
        return state.loadingMaterials;
    }
    static priorityDeleted(state) {
        return state.priorityDeleted;
    }
    static tagDeleted(state) {
        return state.tagDeleted;
    }
    static templates(state) {
        return state.templates;
    }
    constructor(settingsService, messageService, financeService) {
        this.settingsService = settingsService;
        this.messageService = messageService;
        this.financeService = financeService;
    }
    setUser(context, action) {
        context.patchState({ me: action.user });
    }
    successMessage(context, action) {
        this.messageService.add({ severity: 'sucess', summary: 'Uspešno', detail: action.payload.message });
    }
    errorMessage(context, action) {
        this.messageService.add({ severity: 'danger', summary: 'Napaka', detail: action.payload.message });
    }
    searchTags(context, action) {
        context.patchState({ loadingTags: true });
        return this.settingsService.searchTags(action.ctx)
            .pipe(tap((response) => context.patchState({ tags: response.data })), tap(() => context.patchState({ loadingTags: false })));
    }
    searchPriorities(context) {
        context.patchState({ loadingPriorities: true });
        return this.settingsService.searchPriorities({})
            .pipe(tap((response) => context.patchState({ priorities: response.data })), tap(() => context.patchState({ loadingPriorities: false })));
    }
    searchMaterials(context) {
        context.patchState({ loadingMaterials: true });
        return this.settingsService.searchMaterials({})
            .pipe(tap((response) => context.patchState({ materials: response.data })), tap(() => context.patchState({ loadingMaterials: false })));
    }
    loadConfigurationAction(context, action) {
        context.patchState({ loadingConfig: true });
        return this.settingsService.loadConfiguration()
            .pipe(tap((response) => context.patchState({ config: response.data, loadingConfig: false })));
    }
    saveConfigurationAction(context, action) {
        return this.settingsService.saveConfiguration(action.vars);
    }
    loadFinanceConfigSpecsAction(context) {
        context.patchState({ loadingConfig: true });
        return this.financeService.getConfig()
            .pipe(tap((response) => context.patchState({ financeConfigSpec: response, loadingConfig: false })));
    }
    loadSettingsConfigSpecs(context) {
        context.patchState({ loadingConfig: true, settingsConfigSpec: [] });
        return this.settingsService.loadConfigSpecs()
            .pipe(tap((response) => context.patchState({
            settingsConfigSpec: response.data,
            loadingConfig: false,
        })));
    }
    createTag(context, action) {
        return this.settingsService.createTag(action.tag);
    }
    deleteTag(context, action) {
        context.patchState({ tagDeleted: false });
        return this.settingsService.deleteTag(action.tag)
            .pipe(tap(resp => context.patchState({ tagDeleted: resp.data })));
    }
    createPriority(context, action) {
        return this.settingsService.createPriority(action.priority);
    }
    createMaterial(context, action) {
        return this.settingsService.createMaterial(action.material);
    }
    deletePriority(context, action) {
        context.patchState({ priorityDeleted: false });
        return this.settingsService.deletePriority(action.id)
            .pipe(tap(resp => context.patchState({ priorityDeleted: resp.data })));
    }
    deleteMaterial(context, action) {
        context.patchState({ materialDeleted: false });
        return this.settingsService.deleteMaterial(action.id)
            .pipe(tap(resp => context.patchState({ materialDeleted: resp.data })));
    }
    searchDimensionCategories(context, action) {
        return this.settingsService.searchDimensionCategories(action.ctx)
            .pipe(tap((response) => context.patchState({ dimensionCategories: response.data })));
    }
    searchCustomFields(context, action) {
        return this.settingsService.searchCustomFields(action.ctx)
            .pipe(tap((response) => context.patchState({ customFields: response.data })));
    }
    createStatus(context, action) {
        return this.settingsService.createStatus(action.status);
    }
    deleteStatus(context, action) {
        context.patchState({ statusDeleted: false });
        return this.settingsService.deleteStatus(action.id)
            .pipe(tap(resp => context.patchState({ statusDeleted: resp.data })));
    }
    createColumn(context, action) {
        return this.settingsService.createColumn(action.column);
    }
    searchStatuses(context) {
        context.patchState({ loadingStatuses: true });
        return this.settingsService.searchStatuses({})
            .pipe(tap((response) => context.patchState({ statuses: response.data })), tap(() => context.patchState({ loadingStatuses: false })));
    }
    createTemplate(context, action) {
        return this.settingsService.createTemplate(action.template);
    }
    patchTemplate(context, action) {
        return this.settingsService.patchTemplate(action.template);
    }
    deleteTemplate(context, action) {
        return this.settingsService.deleteTemplate(action.id);
    }
    searchTemplates(context, action) {
        return this.settingsService.searchTemplates(action.ctx)
            .pipe(tap((response) => context.patchState({ templates: response.data })));
    }
    syncAllPartners(context, action) {
        return this.financeService.syncPartners();
    }
    syncPartner(context, action) {
        return this.financeService.syncPartner(action.id);
    }
    saveCustomField(context, action) {
        return this.settingsService.createCustomField(action.field)
            .pipe(tap(() => this.messageService.add({ severity: 'sucess', summary: 'Uspešno', detail: 'Polje po meri dodano.' })));
    }
    static ɵfac = function GlobalState_Factory(t) { return new (t || GlobalState)(i0.ɵɵinject(i1.SettingsService), i0.ɵɵinject(i2.MessageService), i0.ɵɵinject(i3.FinanceService)); };
    static ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: GlobalState, factory: GlobalState.ɵfac });
};
__decorate([
    Action(SetUserAction),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, SetUserAction]),
    __metadata("design:returntype", void 0)
], GlobalState.prototype, "setUser", null);
__decorate([
    Action(SuccessAction),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, SuccessAction]),
    __metadata("design:returntype", void 0)
], GlobalState.prototype, "successMessage", null);
__decorate([
    Action(ErrorAction),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, ErrorAction]),
    __metadata("design:returntype", void 0)
], GlobalState.prototype, "errorMessage", null);
__decorate([
    Action(SearchTagsAction, { cancelUncompleted: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, SearchTagsAction]),
    __metadata("design:returntype", Observable)
], GlobalState.prototype, "searchTags", null);
__decorate([
    Action(SearchPrioritiesAction, { cancelUncompleted: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", Observable)
], GlobalState.prototype, "searchPriorities", null);
__decorate([
    Action(SearchMaterialsAction, { cancelUncompleted: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", Observable)
], GlobalState.prototype, "searchMaterials", null);
__decorate([
    Action(LoadConfigurationAction, { cancelUncompleted: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, LoadConfigurationAction]),
    __metadata("design:returntype", void 0)
], GlobalState.prototype, "loadConfigurationAction", null);
__decorate([
    Action(SaveConfigurationAction, { cancelUncompleted: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, SaveConfigurationAction]),
    __metadata("design:returntype", void 0)
], GlobalState.prototype, "saveConfigurationAction", null);
__decorate([
    Action(LoadFinanceConfigSpecsAction, { cancelUncompleted: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", Observable)
], GlobalState.prototype, "loadFinanceConfigSpecsAction", null);
__decorate([
    Action(LoadSettingsConfigSpecsAction, { cancelUncompleted: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", Observable)
], GlobalState.prototype, "loadSettingsConfigSpecs", null);
__decorate([
    Action(CreateTagAction),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, CreateTagAction]),
    __metadata("design:returntype", Observable)
], GlobalState.prototype, "createTag", null);
__decorate([
    Action(DeleteTagAction),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, DeleteTagAction]),
    __metadata("design:returntype", Observable)
], GlobalState.prototype, "deleteTag", null);
__decorate([
    Action(CreatePriorityAction),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, CreatePriorityAction]),
    __metadata("design:returntype", Observable)
], GlobalState.prototype, "createPriority", null);
__decorate([
    Action(CreateMaterialAction),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, CreateMaterialAction]),
    __metadata("design:returntype", Observable)
], GlobalState.prototype, "createMaterial", null);
__decorate([
    Action(DeletePriorityAction),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, DeleteStatusAction]),
    __metadata("design:returntype", Observable)
], GlobalState.prototype, "deletePriority", null);
__decorate([
    Action(DeleteMaterialAction),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, DeleteMaterialAction]),
    __metadata("design:returntype", Observable)
], GlobalState.prototype, "deleteMaterial", null);
__decorate([
    Action(SearchDimensionCategoriesAction, { cancelUncompleted: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, SearchDimensionCategoriesAction]),
    __metadata("design:returntype", Observable)
], GlobalState.prototype, "searchDimensionCategories", null);
__decorate([
    Action(SearchCustomFieldsAction, { cancelUncompleted: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, SearchCustomFieldsAction]),
    __metadata("design:returntype", Observable)
], GlobalState.prototype, "searchCustomFields", null);
__decorate([
    Action(CreateStatusAction),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, CreateStatusAction]),
    __metadata("design:returntype", Observable)
], GlobalState.prototype, "createStatus", null);
__decorate([
    Action(DeleteStatusAction),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, DeleteStatusAction]),
    __metadata("design:returntype", Observable)
], GlobalState.prototype, "deleteStatus", null);
__decorate([
    Action(CreateColumnAction),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, CreateColumnAction]),
    __metadata("design:returntype", Observable)
], GlobalState.prototype, "createColumn", null);
__decorate([
    Action(SearchStatusesAction, { cancelUncompleted: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", Observable)
], GlobalState.prototype, "searchStatuses", null);
__decorate([
    Action(CreateTemplateAction),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, CreateTemplateAction]),
    __metadata("design:returntype", Observable)
], GlobalState.prototype, "createTemplate", null);
__decorate([
    Action(PatchTemplateAction),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, PatchTemplateAction]),
    __metadata("design:returntype", Observable)
], GlobalState.prototype, "patchTemplate", null);
__decorate([
    Action(DeleteTemplateAction),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, DeleteTemplateAction]),
    __metadata("design:returntype", Observable)
], GlobalState.prototype, "deleteTemplate", null);
__decorate([
    Action(SearchTemplatesAction, { cancelUncompleted: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, SearchTemplatesAction]),
    __metadata("design:returntype", Observable)
], GlobalState.prototype, "searchTemplates", null);
__decorate([
    Action(SyncAllPartnersAction, { cancelUncompleted: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, SyncAllPartnersAction]),
    __metadata("design:returntype", Observable)
], GlobalState.prototype, "syncAllPartners", null);
__decorate([
    Action(SyncPartnerAction, { cancelUncompleted: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, SyncPartnerAction]),
    __metadata("design:returntype", Observable)
], GlobalState.prototype, "syncPartner", null);
__decorate([
    Action(SaveCustomFieldAction, { cancelUncompleted: false }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, SaveCustomFieldAction]),
    __metadata("design:returntype", Observable)
], GlobalState.prototype, "saveCustomField", null);
__decorate([
    Selector(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", Array)
], GlobalState, "customFields", null);
__decorate([
    Selector(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", Array)
], GlobalState, "dimensionCategories", null);
__decorate([
    Selector(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", Array)
], GlobalState, "financeConfigSpec", null);
__decorate([
    Selector(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", Array)
], GlobalState, "config", null);
__decorate([
    Selector(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", Array)
], GlobalState, "settingsConfigSpec", null);
__decorate([
    Selector(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", Boolean)
], GlobalState, "loadingConfig", null);
__decorate([
    Selector(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", Array)
], GlobalState, "configSpecs", null);
__decorate([
    Selector(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", Array)
], GlobalState, "tags", null);
__decorate([
    Selector(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", Boolean)
], GlobalState, "loadingTags", null);
__decorate([
    Selector(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", Object)
], GlobalState, "me", null);
__decorate([
    Selector(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", Boolean)
], GlobalState, "loadingStatuses", null);
__decorate([
    Selector(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", Array)
], GlobalState, "statuses", null);
__decorate([
    Selector(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", Array)
], GlobalState, "materials", null);
__decorate([
    Selector(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", Boolean)
], GlobalState, "statusDeleted", null);
__decorate([
    Selector(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", Boolean)
], GlobalState, "materialDeleted", null);
__decorate([
    Selector(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", Array)
], GlobalState, "priorities", null);
__decorate([
    Selector(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", Boolean)
], GlobalState, "loadingPriorities", null);
__decorate([
    Selector(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", Boolean)
], GlobalState, "loadingMaterials", null);
__decorate([
    Selector(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", Boolean)
], GlobalState, "priorityDeleted", null);
__decorate([
    Selector(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", Boolean)
], GlobalState, "tagDeleted", null);
__decorate([
    Selector(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", Array)
], GlobalState, "templates", null);
GlobalState = GlobalState_1 = __decorate([
    State({
        name: 'global',
        defaults: {
            loadingStatuses: false,
            loadingMaterials: false,
            statuses: [],
            materials: [],
            customFields: [],
            statusDeleted: false,
            dimensionCategories: [],
            loadingPriorities: false,
            priorities: [],
            priorityDeleted: false,
            tagDeleted: false,
            templates: [],
            tags: [],
            loadingTags: false,
            me: undefined,
            financeConfigSpec: [],
            settingsConfigSpec: [],
            config: [],
            loadingConfig: false,
            materialDeleted: false,
        },
    }),
    __metadata("design:paramtypes", [SettingsService,
        MessageService,
        FinanceService])
], GlobalState);
export { GlobalState };
