/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class InvoicingService {
    http;
    constructor(http) {
        this.http = http;
    }
    createEstimate(id) {
        console.log('create estimate called');
        return this.http.post(`${environment.base}/invoicing/estimate/${id}`, undefined);
    }
    createInvoice(id) {
        return this.http.post(`${environment.base}/invoicing/${id}`, undefined);
    }
    createDeliveryNote(id) {
        return this.http.post(`${environment.base}/invoicing/delivery_note/${id}`, undefined);
    }
    static ɵfac = function InvoicingService_Factory(t) { return new (t || InvoicingService)(i0.ɵɵinject(i1.HttpClient)); };
    static ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: InvoicingService, factory: InvoicingService.ɵfac });
}
