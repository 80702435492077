import { AuthService } from '../auth/auth.service';
import { Store } from '@ngxs/store';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { AppendMessageAction, SetAuthStateAction } from './state/actions';
import { API } from '../api/apiutil';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../auth/auth.service";
import * as i3 from "@ngxs/store";
export class ChatService {
    http;
    auth;
    store;
    socket;
    constructor(http, auth, store) {
        this.http = http;
        this.auth = auth;
        this.store = store;
        this.createSocket();
    }
    sendMessage(data) {
        return this.send({ event: 'message', data });
    }
    search(ctx = {}) {
        return this.http.get(`${environment.base}/chat`, { params: API.makeParams(ctx) });
    }
    createSocket(path = '/api/v1/chat/ws') {
        const socket = new WebSocket(this.createUrl(path));
        this.bindEventHandlers(socket);
        this.socket = socket;
    }
    bindEventHandlers(socket) {
        socket.onopen = this.startAuth.bind(this);
        socket.onerror = this.socketError.bind(this);
        socket.onclose = this.socketClosed.bind(this);
        socket.onmessage = this.handleSocketMessage.bind(this);
    }
    createUrl(path) {
        const url = new URL(path, environment.production ? location.href : environment.base);
        url.protocol = environment.production ? 'wss' : 'ws';
        return url;
    }
    socketError(err) {
        // TODO: Ignore for now, maybe some error message in the future?
    }
    async socketClosed(err) {
        if (!this.auth.apiKey()) {
            setTimeout(() => this.socketClosed(undefined), 5000);
            return;
        }
        this.store.dispatch(SetAuthStateAction.dispatch(false));
        await new Promise((resolve) => setTimeout(() => resolve(void (0)), 1000));
        this.createSocket();
    }
    handleSocketMessage(message) {
        try {
            const data = JSON.parse(message.data);
            switch (data.event) {
                case 'auth':
                    if (data.data === 'ok') {
                        this.store.dispatch(SetAuthStateAction.dispatch(true));
                    }
                    break;
                case 'message':
                    this.store.dispatch(AppendMessageAction.dispatch(data.data));
                    break;
            }
        }
        catch (e) {
            console.log('Failed to parse socket message');
        }
    }
    send(data) {
        this.socket.send(JSON.stringify(data));
    }
    startAuth() {
        this.send({ event: 'auth', data: this.auth.apiKey() });
    }
    static ɵfac = function ChatService_Factory(t) { return new (t || ChatService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AuthService), i0.ɵɵinject(i3.Store)); };
    static ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: ChatService, factory: ChatService.ɵfac });
}
