import { NgxsModule } from '@ngxs/store';
import { SearchService } from './search/search.service';
import { SearchState } from './state';
import * as i0 from "@angular/core";
import * as i1 from "@ngxs/store";
export class SearchModule {
    static ɵfac = function SearchModule_Factory(t) { return new (t || SearchModule)(); };
    static ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: SearchModule });
    static ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ providers: [SearchService], imports: [NgxsModule.forFeature([SearchState])] });
}
