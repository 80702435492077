import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
export class SearchService {
    searchString;
    constructor() {
        this.searchString = new BehaviorSubject('');
    }
    static ɵfac = function SearchService_Factory(t) { return new (t || SearchService)(); };
    static ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: SearchService, factory: SearchService.ɵfac });
}
