import { __decorate, __metadata } from "tslib";
import { Action, Selector, State } from '@ngxs/store';
import { ChatService } from '../chat.service';
import { AppendMessageAction, PostMessageAction, SetAuthStateAction, SetChatRefAction, SetChatVisibilityAction } from './actions';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../chat.service";
let ChatState = class ChatState {
    chatService;
    static messages(state) {
        return state.messages;
    }
    static ref(state) {
        return state.ref;
    }
    static hasNew(state) {
        return state.hasNew;
    }
    static visible(state) {
        return state.visible;
    }
    constructor(chatService) {
        this.chatService = chatService;
    }
    setAuthState(ctx, action) {
        if (action.hasAuth) {
            return this.chatService.search()
                .pipe(map((response) => ctx.patchState({ hasAuth: action.hasAuth, messages: response.data.reverse() })));
        }
        return ctx.patchState({
            hasAuth: action.hasAuth,
            messages: action.hasAuth ? ctx.getState().messages : [],
        });
    }
    setChatVisibility(ctx, action) {
        return ctx.patchState({
            visible: action.visible,
            hasNew: action.visible,
        });
    }
    setChatRef(ctx, action) {
        return ctx.patchState({
            visible: true,
            ref: action.ref,
        });
    }
    postMessage(ctx, action) {
        this.chatService.sendMessage({ ...action.message });
        return ctx.patchState({ ref: undefined });
    }
    appendMessage(ctx, action) {
        return ctx.patchState({
            hasNew: !ctx.getState().visible,
            messages: [
                ...ctx.getState().messages,
                action.message,
            ],
        });
    }
    static ɵfac = function ChatState_Factory(t) { return new (t || ChatState)(i0.ɵɵinject(i1.ChatService)); };
    static ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: ChatState, factory: ChatState.ɵfac });
};
__decorate([
    Action(SetAuthStateAction),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, SetAuthStateAction]),
    __metadata("design:returntype", void 0)
], ChatState.prototype, "setAuthState", null);
__decorate([
    Action(SetChatVisibilityAction),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, SetChatVisibilityAction]),
    __metadata("design:returntype", void 0)
], ChatState.prototype, "setChatVisibility", null);
__decorate([
    Action(SetChatRefAction),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, SetChatRefAction]),
    __metadata("design:returntype", void 0)
], ChatState.prototype, "setChatRef", null);
__decorate([
    Action(PostMessageAction),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, PostMessageAction]),
    __metadata("design:returntype", void 0)
], ChatState.prototype, "postMessage", null);
__decorate([
    Action(AppendMessageAction),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, AppendMessageAction]),
    __metadata("design:returntype", void 0)
], ChatState.prototype, "appendMessage", null);
__decorate([
    Selector(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", Array)
], ChatState, "messages", null);
__decorate([
    Selector(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", Object)
], ChatState, "ref", null);
__decorate([
    Selector(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", Boolean)
], ChatState, "hasNew", null);
__decorate([
    Selector(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", Boolean)
], ChatState, "visible", null);
ChatState = __decorate([
    State({
        name: 'chat',
        defaults: {
            messages: [],
            visible: true,
            hasNew: false,
            hasAuth: false,
            ref: undefined,
        }
    }),
    __metadata("design:paramtypes", [ChatService])
], ChatState);
export { ChatState };
