import { ActivatedRoute, Router, UrlTree } from '@angular/router';
import { Store } from '@ngxs/store';
import { lastValueFrom } from 'rxjs';
import { UserService } from '../api/user.service';
import { SetUserAction } from '../state/actions';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../api/user.service";
import * as i3 from "@ngxs/store";
export class UserGuardService {
    router;
    userService;
    store;
    activatedRoute;
    constructor(router, userService, store, activatedRoute) {
        this.router = router;
        this.userService = userService;
        this.store = store;
        this.activatedRoute = activatedRoute;
    }
    async canActivate() {
        const user = await lastValueFrom(this.userService.check());
        if (!user || !user.data) {
            await this.router.navigate(['/login'], {
                queryParams: {
                    returnTo: this.router.getCurrentNavigation()?.finalUrl?.toString() || undefined,
                },
            });
            return false;
        }
        this.store.dispatch(SetUserAction.dispatch(user.data));
        return true;
    }
    static ɵfac = function UserGuardService_Factory(t) { return new (t || UserGuardService)(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.UserService), i0.ɵɵinject(i3.Store), i0.ɵɵinject(i1.ActivatedRoute)); };
    static ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: UserGuardService, factory: UserGuardService.ɵfac, providedIn: 'root' });
}
