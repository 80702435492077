import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { API } from './apiutil';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class ClipService {
    http;
    constructor(http) {
        this.http = http;
    }
    search(ctx) {
        return this.http.get(`${environment.base}/clip`, { params: API.makeParams(ctx) });
    }
    createClip(clip) {
        return this.http.post(`${environment.base}/clip`, clip);
    }
    deleteClip(id) {
        return this.http.delete(`${environment.base}/clip/${id}`);
    }
    static ɵfac = function ClipService_Factory(t) { return new (t || ClipService)(i0.ɵɵinject(i1.HttpClient)); };
    static ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: ClipService, factory: ClipService.ɵfac });
}
