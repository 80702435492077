import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { API } from './apiutil';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class RegistrationService {
    http;
    constructor(http) {
        this.http = http;
    }
    getMy(ctx) {
        return this.http.get(`${environment.base}/registration`, { params: API.makeParams(ctx) });
    }
    download(id, ctx) {
        return this.http.get(`${environment.base}/registration/export/xlsx/${id}`, {
            params: API.makeParams(ctx),
            responseType: 'arraybuffer',
            observe: 'response'
        })
            .pipe(map(resp => new Blob([resp.body], { type: resp.headers.get('content-type') })));
    }
    startEvent(type) {
        return this.http.put(`${environment.base}/registration/create`, { type });
    }
    endEvent(eventId) {
        return this.http.put(`${environment.base}/registration/end/${eventId}`, {});
    }
    getUser(ctx, id) {
        return this.http.get(`${environment.base}/registration/admin/${id}`, { params: API.makeParams(ctx) });
    }
    deleteEvent(eventId) {
        return this.http.delete(`${environment.base}/registration/delete/${eventId}`);
    }
    createAdminEvent(payload) {
        return this.http.post(`${environment.base}/registration/admin/create`, payload);
    }
    static ɵfac = function RegistrationService_Factory(t) { return new (t || RegistrationService)(i0.ɵɵinject(i1.HttpClient)); };
    static ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: RegistrationService, factory: RegistrationService.ɵfac });
}
