import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { API } from './apiutil';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class FormatsService {
    http;
    constructor(http) {
        this.http = http;
    }
    search(ctx) {
        return this.http.get(`${environment.base}/format`, { params: API.makeParams(ctx) });
    }
    create(plan) {
        return this.http.post(`${environment.base}/format`, plan);
    }
    static ɵfac = function FormatsService_Factory(t) { return new (t || FormatsService)(i0.ɵɵinject(i1.HttpClient)); };
    static ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: FormatsService, factory: FormatsService.ɵfac });
}
