import { HttpParams } from '@angular/common/http';
export class API {
    static makeParams(ctx) {
        let params = new HttpParams();
        Object.keys(ctx).forEach(key => {
            if (Array.isArray(ctx[key])) {
                const val = ctx[key];
                if (val) {
                    for (const v of val) {
                        params = params.append(key, v);
                    }
                }
            }
            else {
                if (ctx[key]) {
                    params = params.append(key, ctx[key].toString());
                }
            }
        });
        return params;
    }
}
