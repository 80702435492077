import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { environment } from '../../environments/environment';
import { catchError, map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class UserService {
    http;
    constructor(http) {
        this.http = http;
    }
    login(eMail, password) {
        return this.http.post(`${environment.base}/user/login`, { eMail, password })
            .pipe(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        map((response) => response.data));
    }
    check() {
        return this.http.get(`${environment.base}/user`)
            .pipe(catchError(() => {
            return of(undefined);
        }));
    }
    patchUser(data) {
        return this.http.patch(`${environment.base}/user`, data);
    }
    addUser(user) {
        return this.http.post(`${environment.base}/user`, user);
    }
    deleteUser(id) {
        return this.http.delete(`${environment.base}/user/${id}`);
    }
    list() {
        return this.http.get(`${environment.base}/user/list`);
    }
    static ɵfac = function UserService_Factory(t) { return new (t || UserService)(i0.ɵɵinject(i1.HttpClient)); };
    static ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: UserService, factory: UserService.ɵfac });
}
