export class SearchPrioritiesAction {
    ctx;
    static type = '[global] search priorities';
    constructor(ctx) {
        this.ctx = ctx;
    }
    static dispatch(ctx) {
        return new SearchPrioritiesAction(ctx);
    }
}
export class SearchMaterialsAction {
    ctx;
    static type = '[global] search Materials';
    constructor(ctx) {
        this.ctx = ctx;
    }
    static dispatch(ctx) {
        return new SearchMaterialsAction(ctx);
    }
}
export class LoadFinanceConfigSpecsAction {
    static type = '[global] load finance config specs';
    static dispatch() {
        return new LoadFinanceConfigSpecsAction();
    }
}
export class LoadConfigurationAction {
    static type = '[global] load configuration';
    static dispatch() {
        return new LoadConfigurationAction();
    }
}
export class LoadSettingsConfigSpecsAction {
    static type = '[global] load settings config specs';
    static dispatch() {
        return new LoadSettingsConfigSpecsAction();
    }
}
export class SaveConfigurationAction {
    vars;
    static type = '[global] save configuration';
    constructor(vars) {
        this.vars = vars;
    }
    static dispatch(vars) {
        return new SaveConfigurationAction(vars);
    }
}
export class SearchTagsAction {
    ctx;
    static type = '[global] search tags';
    constructor(ctx) {
        this.ctx = ctx;
    }
    static dispatch(ctx) {
        return new SearchTagsAction(ctx);
    }
}
export class CreateTagAction {
    tag;
    static type = '[global] create tag';
    constructor(tag) {
        this.tag = tag;
    }
    static dispatch(tag) {
        return new CreateTagAction(tag);
    }
}
export class DeleteTagAction {
    tag;
    static type = '[global] delete tag';
    constructor(tag) {
        this.tag = tag;
    }
    static dispatch(tag) {
        return new DeleteTagAction(tag);
    }
}
export class SearchCustomFieldsAction {
    ctx;
    static type = '[global] search custom fields';
    constructor(ctx) {
        this.ctx = ctx;
    }
    static dispatch(ctx) {
        return new SearchCustomFieldsAction(ctx);
    }
}
export class SearchDimensionCategoriesAction {
    ctx;
    static type = '[global] search dimension categories';
    constructor(ctx) {
        this.ctx = ctx;
    }
    static dispatch(ctx) {
        return new SearchDimensionCategoriesAction(ctx);
    }
}
export class CreatePriorityAction {
    priority;
    static type = '[global] create priority';
    constructor(priority) {
        this.priority = priority;
    }
    static dispatch(priority) {
        return new CreatePriorityAction(priority);
    }
}
export class CreateMaterialAction {
    material;
    static type = '[global] create material';
    constructor(material) {
        this.material = material;
    }
    static dispatch(material) {
        return new CreateMaterialAction(material);
    }
}
export class DeletePriorityAction {
    id;
    static type = '[global] delete priority';
    constructor(id) {
        this.id = id;
    }
    static dispatch(id) {
        return new DeletePriorityAction(id);
    }
}
export class DeleteMaterialAction {
    id;
    static type = '[global] delete Material';
    constructor(id) {
        this.id = id;
    }
    static dispatch(id) {
        return new DeleteMaterialAction(id);
    }
}
export class CreateStatusAction {
    status;
    static type = '[global] create status';
    constructor(status) {
        this.status = status;
    }
    static dispatch(status) {
        return new CreateStatusAction(status);
    }
}
export class DeleteStatusAction {
    id;
    static type = '[global] delete status';
    constructor(id) {
        this.id = id;
    }
    static dispatch(id) {
        return new DeleteStatusAction(id);
    }
}
export class SearchStatusesAction {
    ctx;
    static type = '[global] search statuses';
    constructor(ctx) {
        this.ctx = ctx;
    }
    static dispatch(ctx) {
        return new SearchStatusesAction(ctx);
    }
}
export class CreateColumnAction {
    column;
    static type = '[global] create column';
    constructor(column) {
        this.column = column;
    }
    static dispatch(column) {
        return new CreateColumnAction(column);
    }
}
export class SuccessAction {
    payload;
    static type = '[global] success action';
    constructor(payload) {
        this.payload = payload;
    }
    static dispatch(payload) {
        return new SuccessAction(payload);
    }
}
export class ErrorAction {
    payload;
    static type = '[global] error action';
    constructor(payload) {
        this.payload = payload;
    }
    static dispatch(payload) {
        return new ErrorAction(payload);
    }
}
export class SetUserAction {
    user;
    static type = '[global] set user action';
    constructor(user) {
        this.user = user;
    }
    static dispatch(user) {
        return new SetUserAction(user);
    }
}
export class SearchTemplatesAction {
    ctx;
    static type = '[global] search templates';
    constructor(ctx) {
        this.ctx = ctx;
    }
    static dispatch(ctx) {
        return new SearchTemplatesAction(ctx);
    }
}
export class CreateTemplateAction {
    template;
    static type = '[global] create template';
    constructor(template) {
        this.template = template;
    }
    static dispatch(template) {
        return new CreateTemplateAction(template);
    }
}
export class PatchTemplateAction {
    template;
    static type = '[global] patch template';
    constructor(template) {
        this.template = template;
    }
    static dispatch(template) {
        return new PatchTemplateAction(template);
    }
}
export class DeleteTemplateAction {
    id;
    static type = '[global] delete template';
    constructor(id) {
        this.id = id;
    }
    static dispatch(id) {
        return new DeleteTemplateAction(id);
    }
}
export class SyncAllPartnersAction {
    static type = '[global] sync all partners';
    static dispatch() {
        return new SyncAllPartnersAction();
    }
}
export class SyncPartnerAction {
    id;
    static type = '[global] sync partner by id';
    constructor(id) {
        this.id = id;
    }
    static dispatch(id) {
        return new SyncPartnerAction(id);
    }
}
export class SaveCustomFieldAction {
    field;
    static type = '[global] save custom field';
    constructor(field) {
        this.field = field;
    }
    static dispatch(field) {
        return new SaveCustomFieldAction(field);
    }
}
