import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { API } from './apiutil';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class PartnersService {
    http;
    constructor(http) {
        this.http = http;
    }
    lookup(ctx) {
        return this.http.get(`${environment.base}/customers/lookup`, {
            params: API.makeParams(ctx),
        });
    }
    search(ctx) {
        return this.http.get(`${environment.base}/customers`, {
            params: API.makeParams(ctx),
        });
    }
    create(partner) {
        return this.http.post(`${environment.base}/customers/`, partner);
    }
    readById(id) {
        return this.http.get(`${environment.base}/customers/${id}`);
    }
    deletePartner(id) {
        return this.http.delete(`${environment.base}/customers/${id}`);
    }
    deletePartnerContact(partnerId, contactId) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return this.http.delete(`${environment.base}/customers/${partnerId}/${contactId}`);
    }
    searchContacts(ctx) {
        return this.http.get(`${environment.base}/customers/contacts`, {
            params: API.makeParams(ctx)
        });
    }
    checkExistence(ctx) {
        return this.http.get(`${environment.base}/customers/exists`, {
            params: API.makeParams(ctx)
        });
    }
    static ɵfac = function PartnersService_Factory(t) { return new (t || PartnersService)(i0.ɵɵinject(i1.HttpClient)); };
    static ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: PartnersService, factory: PartnersService.ɵfac });
}
