import { inject } from '@angular/core';
import { Store } from '@ngxs/store';
import { PostMessageAction, SetChatRefAction } from '../state/actions';
import { EItemType } from '@mvm/api-interfaces';
import { ChatState } from '../state';
import * as i0 from "@angular/core";
import * as i1 from "@ngxs/store";
import * as i2 from "@angular/common";
import * as i3 from "@angular/forms";
import * as i4 from "primeng/inputtextarea";
function ChatInputComponent_ng_container_1_div_2_b_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "b", 10);
    i0.ɵɵtext(1, "Naro\u010Dilo:");
    i0.ɵɵelementEnd();
} }
function ChatInputComponent_ng_container_1_div_2_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 8);
    i0.ɵɵtemplate(1, ChatInputComponent_ng_container_1_div_2_b_1_Template, 2, 0, "b", 9);
    i0.ɵɵelementStart(2, "b", 10);
    i0.ɵɵtext(3);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(4, "span", 10);
    i0.ɵɵtext(5);
    i0.ɵɵelementEnd()();
} if (rf & 2) {
    const ref_r2 = i0.ɵɵnextContext().ngIf;
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ref_r2.refType);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(ref_r2.title);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(ref_r2.text);
} }
function ChatInputComponent_ng_container_1_div_3_b_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "b", 10);
    i0.ɵɵtext(1, "Partner:");
    i0.ɵɵelementEnd();
} }
function ChatInputComponent_ng_container_1_div_3_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 8);
    i0.ɵɵtemplate(1, ChatInputComponent_ng_container_1_div_3_b_1_Template, 2, 0, "b", 9);
    i0.ɵɵelementStart(2, "b", 10);
    i0.ɵɵtext(3);
    i0.ɵɵelementEnd()();
} if (rf & 2) {
    const ref_r2 = i0.ɵɵnextContext().ngIf;
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ref_r2.refType);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(ref_r2.title);
} }
function ChatInputComponent_ng_container_1_Template(rf, ctx) { if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "div", 5);
    i0.ɵɵtemplate(2, ChatInputComponent_ng_container_1_div_2_Template, 6, 3, "div", 6)(3, ChatInputComponent_ng_container_1_div_3_Template, 4, 2, "div", 6);
    i0.ɵɵelementStart(4, "div")(5, "i", 7);
    i0.ɵɵlistener("click", function ChatInputComponent_ng_container_1_Template_i_click_5_listener() { i0.ɵɵrestoreView(_r1); const ctx_r2 = i0.ɵɵnextContext(); return i0.ɵɵresetView(ctx_r2.clearRef()); });
    i0.ɵɵelementEnd()()();
    i0.ɵɵelementContainerEnd();
} if (rf & 2) {
    const ref_r2 = ctx.ngIf;
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", ref_r2.refType === ctx_r2.ItemType.ORDER);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ref_r2.refType === ctx_r2.ItemType.PARTNER);
} }
export class ChatInputComponent {
    store;
    ref$ = inject(Store).select(ChatState.ref);
    text = '';
    ItemType = EItemType;
    constructor(store) {
        this.store = store;
    }
    enter(kEvent) {
        if (kEvent.shiftKey) {
            return;
        }
        const ref = this.store.selectSnapshot(ChatState.ref);
        this.store.dispatch(PostMessageAction.dispatch({
            text: this.text,
            ref: ref?.ref,
            refType: ref?.refType,
        }));
        console.log(this.text);
        this.text = "";
    }
    clearRef() {
        this.store.dispatch(SetChatRefAction.dispatch(undefined));
    }
    static ɵfac = function ChatInputComponent_Factory(t) { return new (t || ChatInputComponent)(i0.ɵɵdirectiveInject(i1.Store)); };
    static ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: ChatInputComponent, selectors: [["mvm-chat-input"]], decls: 6, vars: 4, consts: [[1, "grid"], [4, "ngIf"], [1, "col-12"], [1, "p-field", "p-fluid"], ["rows", "2", "pInputTextarea", "", 3, "ngModelChange", "keyup.enter", "ngModel"], [1, "col-12", "flex", "flex-row", "ref"], ["class", "flex flex-column flex-grow-1", 4, "ngIf"], [1, "fa", "fa-solid", "fa-x", "cursor-pointer", 3, "click"], [1, "flex", "flex-column", "flex-grow-1"], ["class", "text-mini", 4, "ngIf"], [1, "text-mini"]], template: function ChatInputComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵelementStart(0, "div", 0);
            i0.ɵɵtemplate(1, ChatInputComponent_ng_container_1_Template, 6, 2, "ng-container", 1);
            i0.ɵɵpipe(2, "async");
            i0.ɵɵelementStart(3, "div", 2)(4, "div", 3)(5, "textarea", 4);
            i0.ɵɵtwoWayListener("ngModelChange", function ChatInputComponent_Template_textarea_ngModelChange_5_listener($event) { i0.ɵɵtwoWayBindingSet(ctx.text, $event) || (ctx.text = $event); return $event; });
            i0.ɵɵlistener("keyup.enter", function ChatInputComponent_Template_textarea_keyup_enter_5_listener($event) { return ctx.enter($event); });
            i0.ɵɵelementEnd()()()();
        } if (rf & 2) {
            i0.ɵɵadvance();
            i0.ɵɵproperty("ngIf", i0.ɵɵpipeBind1(2, 2, ctx.ref$));
            i0.ɵɵadvance(4);
            i0.ɵɵtwoWayProperty("ngModel", ctx.text);
        } }, dependencies: [i2.NgIf, i3.DefaultValueAccessor, i3.NgControlStatus, i3.NgModel, i4.InputTextarea, i2.AsyncPipe], styles: [".ref[_ngcontent-%COMP%] {\n  border: 0 1px #2B2B30;\n  background: #F7F9FA;\n}"] });
}
