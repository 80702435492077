import { __decorate, __metadata } from "tslib";
import { Action, Selector, State } from '@ngxs/store';
import { SearchAction } from './actions';
import * as i0 from "@angular/core";
let SearchState = class SearchState {
    static ctx(state) {
        return state.ctx;
    }
    search(context, action) {
        context.patchState({ ctx: action.ctx });
    }
    static ɵfac = function SearchState_Factory(t) { return new (t || SearchState)(); };
    static ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: SearchState, factory: SearchState.ɵfac });
};
__decorate([
    Action(SearchAction, { cancelUncompleted: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, SearchAction]),
    __metadata("design:returntype", void 0)
], SearchState.prototype, "search", null);
__decorate([
    Selector(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", Object)
], SearchState, "ctx", null);
SearchState = __decorate([
    State({
        name: 'search',
        defaults: {
            ctx: undefined,
        },
    })
], SearchState);
export { SearchState };
