import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { API } from './apiutil';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class KnowledgeService {
    http;
    constructor(http) {
        this.http = http;
    }
    create(payload) {
        return this.http.post(`${environment.base}/knowledge`, payload);
    }
    createSub(id, payload) {
        return this.http.post(`${environment.base}/knowledge/${id}`, payload);
    }
    update(id, payload) {
        return this.http.patch(`${environment.base}/knowledge/${id}`, payload);
    }
    search(ctx) {
        return this.http.get(`${environment.base}/knowledge`, {
            params: API.makeParams(ctx),
        });
    }
    tree(ctx) {
        return this.http.get(`${environment.base}/knowledge/tree`, {
            params: API.makeParams(ctx),
        });
    }
    read(id) {
        return this.http.get(`${environment.base}/knowledge/${id}`);
    }
    remove(id) {
        return this.http.delete(`${environment.base}/knowledge/${id}`);
    }
    static ɵfac = function KnowledgeService_Factory(t) { return new (t || KnowledgeService)(i0.ɵɵinject(i1.HttpClient)); };
    static ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: KnowledgeService, factory: KnowledgeService.ɵfac });
}
