import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
export class OrderChangeDetectionService {
    setUpAllChangesSSE() {
        return this.setUpSSE(`${environment.base}/order/changes`);
    }
    setUpOrderChangesSSE(id) {
        return this.setUpSSE(`${environment.base}/order/changes/${id}`);
    }
    setUpSSE(url) {
        const es = new EventSource(url);
        return new Observable((sub) => {
            es.onerror = ((ev) => sub.error(ev));
            es.onmessage = ((event) => sub.next(JSON.parse(event.data)));
            return () => es.close();
        });
    }
    static ɵfac = function OrderChangeDetectionService_Factory(t) { return new (t || OrderChangeDetectionService)(); };
    static ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: OrderChangeDetectionService, factory: OrderChangeDetectionService.ɵfac });
}
