import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { EUserRole } from '@mvm/api-interfaces';
import { Store } from '@ngxs/store';
import { GlobalState } from '../state';
import { SetUserAction } from '../state/actions';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@ngxs/store";
export class AuthService {
    document;
    router;
    store;
    key = '';
    get roles() {
        const me = this.store.selectSnapshot(GlobalState.me);
        return me?.roles || [];
    }
    get isAdmin() {
        return this.roles.includes(EUserRole.ADMIN);
    }
    get isEditor() {
        return this.roles.includes(EUserRole.EDITOR);
    }
    get isExternal() {
        return this.roles.includes(EUserRole.EXTERNAL);
    }
    get isDesigner() {
        return this.roles.includes(EUserRole.DESIGNER);
    }
    get isCustomer() {
        return this.roles.includes(EUserRole.CUSTOMER);
    }
    get canWrite() {
        return this.isAdmin || this.isEditor;
    }
    get id() {
        const me = this.store.selectSnapshot(GlobalState.me);
        return me?.id;
    }
    constructor(document, router, store) {
        this.document = document;
        this.router = router;
        this.store = store;
        if (!this.key && localStorage) {
            this.key = localStorage.getItem('mvmpro.token');
        }
        // console.trace('auth');
        console.log(Math.random(), 'auth');
    }
    getDomain() {
        return this.document.location.hostname;
    }
    setKey(key) {
        this.key = key;
        if (localStorage) {
            localStorage.setItem('mvmpro.token', key);
        }
    }
    apiKey() {
        return this.key;
    }
    logout(returnTo) {
        this.key = undefined;
        this.store.dispatch(SetUserAction.dispatch(undefined));
        if (localStorage) {
            localStorage.removeItem('mvmpro.token');
        }
        this.router.navigate(['/login'], { queryParams: { returnTo } });
    }
    static ɵfac = function AuthService_Factory(t) { return new (t || AuthService)(i0.ɵɵinject(DOCUMENT), i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.Store)); };
    static ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: AuthService, factory: AuthService.ɵfac, providedIn: 'root' });
}
